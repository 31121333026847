import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Footer, Header } from "./Components";
import { Community, Home } from "./Pages";
import ScrollToTop from "./Components/ScrollToTop";
import { useState, useEffect } from "react";
import { db } from "./firebase/auth";
import { onSnapshot, collection } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";

function App() {
  const [travelPackages, setTravelPackages] = useState([]);
  const [packageLoading, setPackageLoading] = useState(true);

  useEffect(() => {
    const unsubscribeTravelPackages = onSnapshot(
      collection(db, "travelPackages"),
      (snapshot) => {
        const updatedTravelPackages = [];
        snapshot.forEach((doc) => {
          updatedTravelPackages.push({ ...doc.data(), id: doc.id });
        });
        setTravelPackages(
          updatedTravelPackages.filter(
            (travelPackage) => travelPackage.isActive === true
          )
        );
        setPackageLoading(false);
      }
    );
    return unsubscribeTravelPackages;
  }, []);

  return (
    <div
      className="App min-h-screen bg-white"
      // style={{ backgroundColor: "#f6f8ff" }}
    >
      {" "}
      <Header />
      {/* <div className="h-8/12  bg-pink-100"> backgroundColor</div> */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              travelPackages={travelPackages}
              packageLoading={packageLoading}
            />
          }
        />
        <Route path="/community" element={<Community />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
      <ScrollToTop />
      <ToastContainer />
    </div>
  );
}

export default App;
