import React from "react";

export const Community = () => {
  return (
    <div className="community">
      <h1>Community Page</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt
        voluptates, nobis, voluptate, quibusdam quos voluptatum quas quod
        voluptas fugiat magni quidem. Quisquam, voluptatum. Quibusdam, quia
        voluptatibus. Quae, voluptas. Quisquam, voluptatum.
      </p>
    </div>
  );
};
