export const PrivacyPolicy = () => {
    return (

        <div className='pt-28 w-full flex flex-col gap-8 justify-center px-24 py-16 xl:pt-24 lg:px-10 md:px-5 sm:px-4 sm:gap-6'
            style={{
                overscrollBehavior: "contain",
            }}
        >
            <div className="flex flex-col gap-8">
                <h3 className="text-red-800 font-bold text-3xl Aceh text-left w-full">
                    Privacy Policy
                </h3>
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    What information do we collect?
                </h1>
            </div>

            <ul className="gap-2 flex flex-col font-semibold md:text-sm">
                <li>
                    - We collect information from you when you subscribe to our
                    community.
                </li>
                <li>
                    - When booking, ordering or registering on our site, you may be
                    asked to enter your name, e-mail address, mailing address, phone
                    number, or credit card information.
                </li>
            </ul>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    What do we use your information for?
                </h1>
                <p className="font-semibold text-gray-700 text-sm">
                    Any of the information we collect from you may be used in one of the following ways
                </p>
                <ul className="gap-2 flex my-2 flex-col font-semibold md:text-sm">
                    <li>-To personalize your experience</li>
                    <li>
                        -Your information helps us to better respond to your individual
                        needs.
                    </li>
                    <li>-To improve our website </li>
                    <li>-To improve customer service</li>
                    <li>-To process transactions</li>
                </ul>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    How do we protect your information?
                </h1>
                <p className="font-semibold md:text-sm">
                    We implement a variety of security measures to maintain the safety
                    of your personal information when you enter, submit, or access your
                    personal information.
                </p>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    Sharing of information by us
                </h1>
                <ul className="gap-4 flex flex-col font-semibold md:text-sm">
                    <li>
                        - We may share the information about you with our subsidiaries and
                        affiliated companies primarily for business and operational
                        purposes
                    </li>
                    <li>
                        - We may share your information with third party service providers
                        that provide business, professional or technical support functions
                        on our behalf
                    </li>
                    <li>
                        - Your information may also be shared with our sponsors, partners,
                        advertisers, advertising networks and analytics companies or other
                        third parties in connection with marketing, promotions and other
                        offers
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    Third party services
                </h1>
                <p className="font-semibold md:text-sm">
                    Third party providers used by us will only collect, use and disclose
                    your information to the extent necessary to allow them perform the
                    services they provide.
                </p>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    Changes to privacy policy
                </h1>
                <p className="font-semibold md:text-sm">
                    The privacy policy may be subjected to certain changes. Please check
                    back frequently to see any updates or changes to our privacy policy.
                </p>
            </div>
            <div className="flex flex-col gap-2">
                <h1 className="text-black Aceh font-bold text-2xl md:text-xl">
                    Contact Us
                </h1>
                <p className="font-semibold md:text-sm">
                    If you have any questions or concerns regarding this privacy policy,
                    please contact us at:
                    <br></br>
                    <span className="font-bold text-red-800 py-2">
                        Re: CRYSTALVEEY PRIVACY POLICY
                    </span>
                    <br></br>
                    <a
                        href="mailto:office.crystalveey@gmail.com"
                        className="hover:text-gray-750 mt-2"
                    >
                        <i className="fas fa-envelope" />
                        {/* office.crystalveey@gmail.com */}
                        info@crystalveey.com
                    </a>
                </p>
            </div>
        </div>
    )
}