import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getColorsHex } from "../../config/getColorHex";

const secondaryAppConfig = {
  apiKey: "AIzaSyDWJE0ytStDj7tYrYe9cc7aYwh4IqMNLAI",
  authDomain: "crystaveey-atelier.firebaseapp.com",
  projectId: "crystaveey-atelier",
  storageBucket: "crystaveey-atelier.appspot.com",
  messagingSenderId: "778305329441",
  appId: "1:778305329441:web:093440c87a9af9d12ec660",
  measurementId: "G-BDGF3RM5LR",
};

const secondaryApp = initializeApp(secondaryAppConfig, "secondary");

const db = getFirestore(secondaryApp);

export const NewReleased = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const unsubscribeProducts = onSnapshot(
      collection(db, "products"),
      (snapshot) => {
        const products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // set product if it is pubished, then set newArrivals if product is newArrival and published
        setProducts(products.filter((product) => product.published));
      }
    );
    return () => unsubscribeProducts();
  }, []);

  const [newArrival, setNewArrival] = useState([]);

  useEffect(() => {
    const newArrival = products.filter((item) => item.newArrival === true);
    setNewArrival(newArrival);
  }, [products]);
  // const items = newArrival;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="bg-gray-50 font-family font-inter flex flex-col gap-10 items-center py-14 p-10 xl:px-0 sm:py-10 sm:px-0 sm:gap-5">
      <div className="flex flex-col gap-4">
      <h3 className="text-gray-900 font-bold text-4xl Aceh  xl:text-4xl md:text-2xl">
          {" "}
          Top Selling Designs
        </h3>
      </div>
      <div className="w-11/12 xl:w-[calc(100vw-60px)] md:w-[calc(100vw-40px)]">
        <Swiper
          // navigation={true}
          slidesPerView={"auto"}
          watchSlidesProgress
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper w-full"
        >
          {newArrival.map((item) => {
            return (
              <SwiperSlide
                key={item.id}
                // data-aos="fade-up"
                // data-aos-duration="4000"
                // data-aos-delay="200"
                className="max-w-max sm:h-max"
              >
                <div className="pb-12 pr-5 w-max">
                  <a
                    href={`https://atelier.crystalveey.com/products/${item.id}`}
                    target="_blank"
                    // key={product.id}
                    className="z-0 relative font-public-sans w-[280px] flex flex-col bg-white border xl:w-[270px] lg:w-[250px] md:w-[230px] sm:w-[200px] border-gray-200 shadow-md transition duration-300 ease-in-out"
                    onMouseEnter={() => setIsHovered(item.id)}
                    onMouseLeave={() => setIsHovered(null)} rel="noreferrer"
                  >
                    <div>
                      <img
                        src={
                          isHovered === item.id && item.images.length > 0
                            ? item.images[0].backImage
                            : item.images[0].frontImage
                        } // Check if hovered and if multiple images are available
                        alt={item.name}
                        className="h-[370px] w-full object-top xl:h-[350px] lg:h-[340px] md:h-[300px] sm:h-[280px]"
                      />
                    </div>
                    {isHovered === item.id && (
                      <button className="w-full sm:hidden h-full bg-black bg-opacity-10 text-white font-medium  text-x py-2 transition duration-300 ease-in-out absolute z-10 bottom-0 left-0">
                        Shop
                      </button>
                    )}
                    <div className="p-3 flex flex-col gap-3 text-left border-t text-black lg:gap-2.5 sm:gap-2">
                      <div className="flex flex-col gap-1">
                        <h4 className="text-[1.1rem] font-semibold lg:text-base sm:text-[0.95rem]">
                          {item.name}
                        </h4>
                        <p className="text-sm">
                          {item.price.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                          })}
                        </p>
                      </div>
                      <div className="flex gap-3">
                        {item.colors.slice(0, 5).map((color, index) => (
                          <div
                            key={index}
                            className="rounded-full w-5 h-5 border border-gray-200 md:h-4 md:w-4"
                            style={{
                              backgroundColor: `${getColorsHex(color)}`,
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* <a to="/new-arrivals" href="https://atelier.crystalveey.com/new-arrivals">
        <button className="px-9 py-2 bg-gray-950 hover:bg-white border border-gray-950 hover:text-black text-white font-medium text-base transition duration-300 ease-in-out sm:text-sm sm:px-8">
          See More
        </button>
      </a> */}
    </div>
  );
};
