import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import reminato from "../Images/paertners/REMINATO.png";
import covenant from "../Images/paertners/covenant.png";
import airp from "../Images/paertners/airp.png";
import turkey from "../Images/paertners/turkey.png";
import awa from "../Images/paertners/awa.png";
import qatar from "../Images/paertners/qatar.png";
import kenya from "../Images/paertners/kenya.png";
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "swiper/css";

export const Footer = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const elementId = e.target.getAttribute("href");
    const element = document.querySelector(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/";
    }
  };

  return (
    <>
      <div className="flex flex-col gap-10 px-16 pl-40 pt-16 pb-8 bg-[#000000] text-[#FFFFFF] 2xl:pl-20 xl:pl-7 sm:gap-6 sm:pl-4 sm:px-5">
        <div className="flex justify-between pr-32 2xl:pr-16 xl:pr-7  lg:flex lg:flex-wrap lg:gap-16 sm:gap-8 sm:pr-0">
          <div className="flex flex-col gap-4 w-max lg:order-1 sm:w-full">
            <h2 className="font-lifesavers font-bold text-2xl text-[#FFFFFF]">
              CRYSTALVEÉY
            </h2>
            <p className=" text-gray-300 font-semibold text-base">
              Fashion| Travel| Events| Consultations.
            </p>
          </div>
          <div className="flex gap-36  2xl:gap-20 xl:gap-12 lg:w-full lg:order-3 lg:justify-between lg:pr-10 sm:flex-wrap">
            <ul className="flex flex-col gap-6 ">
              <li className="text-[#FFFFFF] font-bold hover:text-gray-750 cursor-pointer w-max">
                Company
              </li>
              <a
                href="#about"
                onClick={handleClick}
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                About Us
              </a>
              <Link to="/privacy-policy">
                <li className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max">
                  Privacy Policy
                </li>
              </Link>
              <a
                href="http://blog.crystalveey.com"
                target="_blank"
                rel="noreferrer"
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                Blog
              </a>
            </ul>
            <ul className="flex flex-col gap-6 w-max">
              <li className="text-[#FFFFFF] font-bold hover:text-gray-750 cursor-pointer">
                Our Diversified Portfolio
              </li>
              <a
                href="http://atelier.crystalveey.com/"
                target="_blank"
                rel="noreferrer"
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                Atelier
              </a>
              <a
                href="http://explore.crystalveey.com"
                target="_blank"
                rel="noreferrer"
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                ExploreCrystalveey
              </a>
              <a
                href="http://wholesquare.crystalveey.com/"
                target="_blank"
                rel="noreferrer"
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                WholeSquare
              </a>
              <a
                href="http://charity.crystalveey.com"
                target="_blank"
                rel="noreferrer"
                className="text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max"
              >
                CVC Charity
              </a>
            </ul>
            <ul className="flex flex-col gap-6 w-max">
              <li className="text-[#FFFFFF] font-bold hover:text-gray-750 cursor-pointer">
                Contact
              </li>
              <li className=" flex gap-1 items-center text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max">
                <a
                  href="mailto:info@crystalveey.com"
                  className="hover:text-gray-750 inline-flex gap-2 items-center"
                >
                  <span className="text-[#38bdf8]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      fill="#74C0FC"
                      viewBox="0 0 512 512"
                    >
                      <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                    </svg>
                  </span>
                  <span>info@crystalveey.com</span>
                </a>
              </li>
              <li className=" flex gap-1 items-center text-[#FFFFFF] font-semibold text-sm cursor-pointer w-max">
                <a
                  href="tel:+2348126091411"
                  className="hover:text-gray-750 inline-flex gap-2 items-center"
                >
                  <span className="text-[#38bdf8]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      fill="#74C0FC"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#74C0FC"
                        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                      />
                    </svg>
                  </span>
                  <span>+234 812 609 1411</span>
                </a>
              </li>
              <li className=" flex gap-1 items-center text-[#FFFFFF] font-semibold text-sm cursor-pointer w-full">
                <span className="text-[#38bdf8]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="#74C0FC"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="#74C0FC"
                      d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                    />
                  </svg>
                </span>
                <span className="max-w-[250px]">
                  Olu-Olusegun avenue off Mobil road, Lekki. Lagos state,
                  Nigeria
                </span>
              </li>
            </ul>
          </div>
        </div>

        <hr className="border border-slate-700 " />
        <Swiper
          // breakpoints={breakpoints}
          slidesPerView={4}
          spaceBetween={10}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
          className="mySwiper py-10 text-white w-4/5 sm:w-full"
        >
          <SwiperSlide className="text-white">
            <img src={turkey} alt="turkey" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
          <SwiperSlide className="text-white">
            <img src={reminato} alt="reminato" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
          <SwiperSlide className="text-white">
            <img src={airp} alt="airpeace" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
          <SwiperSlide className="text-white">
            <img src={covenant} alt="covenant" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
          <SwiperSlide className="text-white">
            <img src={kenya} alt="kenya" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>

          <SwiperSlide className="text-white">
            <img src={qatar} alt="qutar" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
          <SwiperSlide className="text-white">
            <img src={awa} alt="awa" className="w-40 h-20 sm:h-10" />
          </SwiperSlide>
        </Swiper>

        <div className=" border-gray-100 pt-6 text-center">
          <p className="font-semibold base">
            &copy; {new Date().getFullYear()} Crystalveey Merch
            <span className="text-[#9ea1a4] pl-2"> All Rights Reserved</span>
          </p>
        </div>
      </div>
    </>
  );
};
