export const colors = [
  "Black",
  "White",
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
  "Purple",
  "Pink",
  "Brown",
  "Grey", // Consider using "Gray" for US spelling consistency
  "Beige",
  "Gold",
  "Silver",
  "Multi",
  "Navy",
  "Maroon",
  "Teal",
  "Turquoise",
  "Olive",
  "Fuchsia",
  "Lime",
  "Violet",
  "Magenta",
  "Charcoal",
  "Ivory",
  "Khaki",
  "Taupe",
  "Crimson",
  "Lavender",
  "Mauve",
  "Mint",
  "Salmon",
  "Coral",
  "Slate",
  "Indigo",
  "Cyan",
  "Olive Green",
  "Peach",
  "Sky Blue",
  "Slate",
  "Coral",
  "Mint",
];

export const colorsObject = [
  { name: "Black", hex: "#000000" },
  { name: "White", hex: "#FFFFFF" },
  { name: "Red", hex: "#FF0000" },
  { name: "Blue", hex: "#0000FF" },
  { name: "Green", hex: "#008000" },
  { name: "Yellow", hex: "#FFFF00" },
  { name: "Orange", hex: "#FFA500" },
  { name: "Purple", hex: "#800080" },
  { name: "Pink", hex: "#FFC0CB" },
  { name: "Brown", hex: "#A52A2A" },
  { name: "Grey", hex: "#808080" }, // Consider using "Gray" for US spelling consistency
  { name: "Beige", hex: "#F5F5DC" },
  { name: "Gold", hex: "#FFD700" },
  { name: "Silver", hex: "#C0C0C0" },
  {
    name: "Multi",
    hex: "linear-gradient(to bottom right, blue, green, red, yellow)",
  },
  { name: "Navy", hex: "#000080" },
  { name: "Maroon", hex: "#800000" },
  { name: "Teal", hex: "#008080" },
  { name: "Turquoise", hex: "#40E0D0" },
  { name: "Olive", hex: "#808000" },
  { name: "Fuchsia", hex: "#FF00FF" },
  { name: "Lime", hex: "#00FF00" },
  { name: "Violet", hex: "#EE82EE" },
  { name: "Magenta", hex: "#FF00FF" },
  { name: "Charcoal", hex: "#36454F" },
  { name: "Ivory", hex: "#FFFFF0" },
  { name: "Khaki", hex: "#F0E68C" },
  { name: "Taupe", hex: "#483C32" },
  { name: "Crimson", hex: "#DC143C" },
  { name: "Lavender", hex: "#E6E6FA" },
  { name: "Mauve", hex: "#E0B0FF" },
  { name: "Mint", hex: "#98FF98" },
  { name: "Salmon", hex: "#FA8072" },
  { name: "Coral", hex: "#FF7F50" },
  { name: "Slate", hex: "#708090" },
  { name: "Indigo", hex: "#4B0082" },
  { name: "Cyan", hex: "#00FFFF" },
  { name: "Olive Green", hex: "#6B8E23" },
  { name: "Peach", hex: "#FFDAB9" },
  { name: "Sky Blue", hex: "#87CEEB" },
  { name: "Slate", hex: "#708090" },
  { name: "Coral", hex: "#FF7F50" },
  { name: "Mint", hex: "#98FF98" },
];

export const sizes = [
  "6",
  "8",
  "10",
  "12",
  "14",
  "16",
  "18",
  "20",
  "3XS", // "Extra Extra Extra Small
  "XXS", // "Extra Extra Small
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
  "4XL",
  "5XL",
  "6XL",
  "7XL",
  "8XL",
];

export const categories = [
  "Clothing",
  "Shoes",
  "Accessories",
  "2-Piece",
  "Hoodies",
];
