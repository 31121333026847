export const handleFormatPhoneNumber = (selectedCountryCode, phoneNumber) => {
  if (
    selectedCountryCode === "234" &&
    phoneNumber.startsWith("0") &&
    phoneNumber.length === 11
  ) {
    // Remove the leading 0 and set the rest as updatedPhoneNumber
    const updatedNumber = phoneNumber.slice(1);
    return "+" + selectedCountryCode + updatedNumber;
  } else {
    // If the conditions are not met, set the phone number as is
    return "+" + selectedCountryCode + phoneNumber;
  }
};
