import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import {
  faXmarkCircle,
  faCalendar,
  faLocationDot,
  faRoute,
  faClock,
  faPlaneCircleCheck,
  faMugSaucer,
  faHotel,
  faTruckPlane,
  faBus,
  faPlane,
  faCartFlatbedSuitcase,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Button from "@mui/material/Button";

export const ViewDetailModal = ({ isOpen, setIsOpen, data }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 64px)",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: "20px",
    "@media (max-width: 639px)": {
      width: "calc(100% - 34px)",
    //   height: "calc(100% - 0px)",
    //   borderRadius: "0px",
      p: "10px",
    },
  };

  const handleGetDateRange = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);
    const formattedStartDate = startDate.format("Do MMM");
    let formattedEndDate = endDate.format("Do MMM");
    if (!startDate.isSame(endDate, "month")) {
      formattedEndDate = endDate.format("Do MMM YYYY");
    }
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const allActivities = data?.visitingCities?.flatMap(
    (city) => city.activities
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <div className="flex flex-col gap-4">
            <button onClick={() => setIsOpen(false)} className="place-self-end">
              <FontAwesomeIcon
                icon={faXmarkCircle}
                className="text-gray-500 h-5 w-5"
              />
            </button>
            <div className="max-h-[500px] overflow-y-scroll flex flex-col gap-5 p-2 pb-5">
              <div className="flex w-full justify-between items-center sm:flex-col sm:gap-2 sm:items-start">
                <h2 className="text-2xl font-bold text-gray-800">
                  {data?.title}
                </h2>
                <div className="flex gap-2 items-center">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="w-4 h-4 text-[#00AEEF]"
                  />
                  <p className="text-gray-500 text-sm">
                    {handleGetDateRange(data?.startDate, data?.endDate)}
                  </p>
                </div>
              </div>
              <img
                src={data?.images?.imageOne}
                alt={data?.title}
                className="w-full h-72 rounded-md object-cover"
              />
              <div className="flex flex-wrap gap-8 gap-y-6 items-center">
                <div className="flex gap-2.5 items-center">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="w-5 h-5 text-[#00AEEF]"
                  />
                  <p className="text-base font-semibold text-black">
                    {data?.duration} days
                  </p>
                </div>
                <div className="flex gap-2.5 items-center">
                  <FontAwesomeIcon
                    icon={faRoute}
                    className="w-5 h-5 text-[#00AEEF]"
                  />
                  <p className="text-base font-semibold text-black">
                    {allActivities?.length} stops
                  </p>
                </div>
                <div className="flex gap-2.5 items-center">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="w-5 h-5 text-[#00AEEF]"
                  />
                  <p className="text-base font-semibold text-black">
                    {data?.visitingCities?.map((city) => city.name).join(" · ")}
                  </p>
                </div>
              </div>
              <hr
                className="w-full my-1"
                style={{ borderColor: "rgba(145, 158, 171, 0.2)" }}
              />
              <div className="flex flex-col gap-4">
                <h4 className="font-bold text-xl text-[#00AEEF]">Inclusions</h4>
                <ul className="grid grid-cols-2 grid-flow-row gap-3 list-disc px-6 gap-x-28 sm:flex sm:flex-col sm:px-2">
                  {data?.inclusions?.map((item, index) => (
                    <li
                      key={index}
                      className="flex gap-2.5 items-center font-semibold text-base text-black font-sans"
                    >
                      {item?.toLowerCase().includes("flight") ? (
                        <FontAwesomeIcon
                          icon={faPlaneCircleCheck}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("breakfast") ? (
                        <FontAwesomeIcon
                          icon={faMugSaucer}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("hotel") ||
                        item?.toLowerCase().includes("accommodation") ? (
                        <FontAwesomeIcon
                          icon={faHotel}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("transport") ||
                        item?.toLowerCase().includes("transfer") ? (
                        <FontAwesomeIcon
                          icon={faTruckPlane}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("tour") ? (
                        <FontAwesomeIcon
                          icon={faBus}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("lunch") ? (
                        <FontAwesomeIcon
                          icon={faUtensils}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("airport protocols") ||
                        item?.toLowerCase().includes("airport assistance") ? (
                        <FontAwesomeIcon
                          icon={faCartFlatbedSuitcase}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : item?.toLowerCase().includes("travel") ? (
                        <FontAwesomeIcon
                          icon={faPlane}
                          className="w-5 h-5 text-[#FFC223]"
                        />
                      ) : (
                        ""
                      )}
                      {/* capitalize first character or leter */}
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              //   link
              href={`https://explore.crystalveey.com/grouptrips/${data?.id}`}
              target="_blank"
              className="w-max flex justify-self-center place-self-center bg-black"
            >
              Book Now
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
