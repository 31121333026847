import React from "react";
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  SectionSix,
} from "../Components/HomeComponents";
import { NewReleased } from "../Components/HomeComponents/NewReleased";
import vecOne from "../Images/vec-one.png";
import { Helmet } from "react-helmet-async";

export const Home = ({ travelPackages, packageLoading }) => {
  return (
    <div className=" text-center relative flex Andis flex-col  ">
      <Helmet>
        <title> CRYSTALVEÉY</title>
        <meta
          name="description"
          content="Crystalveey is a portfolio company encompassing a fashion merchandize and re-commerce brand that produces quality clothing for both male and female, a like-minded community that fuses the worlds of adventure, fun and healthy lifestyle and a travel agency that offers affordable vacation packages to various destinations."
        />
        <meta name="og:site_name" content="Crystalveey Merch" />
        <link rel="canonical" href="https://Crystalveey.com/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Crystalveey Merch",
            url: "https://crystalveey.com/",
            logo: "https://crystalveey.com/assets/logo.png",
            sameAs: [
              "https://twitter.com/crystalveeyshop",
              "https://www.instagram.com/explorecrystalveey/",
              "https://www.facebook.com/crystalveeyshop",
            ],
          })}
        </script>
      </Helmet>
      <img
        src={vecOne}
        alt="vecOne"
        className="absolute right-0 w-96 lg:hidden"
      />
      <SectionOne />
      <SectionTwo />
      <SectionThree travelPackages={travelPackages} />
      <NewReleased />
      <SectionFive />
      <SectionSix />
    </div>
  );
};
