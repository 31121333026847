import {
  Configuration,
  ContactsApi,
} from "@elasticemail/elasticemail-client-ts-axios";

const config = new Configuration({
  apiKey: process.env.REACT_APP_ELASTIC_EMAIL_API_KEY_2,
});

const contactsApi = new ContactsApi(config);

// Function to add a contact to an existing list
const addContacts = (contacts, listNames) => {
  contactsApi
    .contactsPost(contacts, listNames)
    .then((response) => {
      console.log("API called successfully.");
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export { contactsApi, addContacts };
